import {
  ComputedRef,
  Ref, computed, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { ColumnFilter } from './interfaces/useTableInterfaces';
import { RowSelection } from './interfaces/useTable';

/**
 * A Row is just a record of key value pairs.
 * One of the keys must be a unique identifier
 */
type Row = Record<string, any>;

type Props = {
  initialiseStore: (
    orderBy?: string | null,
    searchBy?: string | null,
    columnFilters?: ColumnFilter[],
    page?: number,
    maxPageSize?: number,
  ) => Promise<any>;
  columnNames: string[];
  rows: ComputedRef<Row[]>;
  rowKey: string;
};

export default (
  {
    initialiseStore,
    columnNames,
    rows,
    rowKey,
  }: Props,
) => {
  const route = useRoute();

  /* Table sorting */
  const isValidOrderByQuery = (query: any) => {
    const regex = new RegExp(`^(${columnNames.join('|')}):(asc|desc)$`);
    return regex.test(query);
  };

  const orderByQueryParam = () => {
    const val = route.query.orderBy;
    if (typeof val === 'string' && isValidOrderByQuery(val)) {
      return val;
    }
    return null;
  };

  const columnFiltersQueryParams = (): ColumnFilter[] => {
    const columnNamesInQuery = Object.keys(route.query).filter((key) => columnNames.includes(key));
    return columnNamesInQuery.map((columnName) => {
      const values = Array.isArray(route.query[columnName])
        ? route.query[columnName] : [route.query[columnName]];
      return {
        columnName,
        values: values as string[],
      };
    });
  };

  /** Table pagination */
  const pageAndMaxPageSizeQueryParams = () => {
    const pageParam = parseInt(route.query.page?.toString() ?? '', 10);
    const maxPageSizeParam = parseInt(route.query.maxPageSize?.toString() ?? '', 10);
    return { page: pageParam, maxPageSize: maxPageSizeParam };
  };

  /* Table filtering (by search) */
  const searchByQueryParam = () => {
    const val = route.query.searchBy;
    if (val) {
      return val.toString();
    }
    return null;
  };

  /* Row selection */
  const rowSelection: Ref<RowSelection> = ref({});
  const rowIds: ComputedRef<string[]> = computed(() => rows.value.map((row: Row) => row[rowKey]));

  const selectedRowIds: ComputedRef<string[]> = computed(() => Object.keys(rowSelection.value)
    .filter((key: string) => rowSelection.value[key].isSelected === true));

  const selectedRows: ComputedRef<any[]> = computed(
    () => selectedRowIds.value.map((id) => rowSelection.value[id].value),
  );

  const isAllRowsSelected = computed(() => {
    const ids = rowIds?.value ?? [];
    return ids.every((val) => selectedRowIds.value.includes(val));
  });

  function toggleSelectRow(id: string) {
    const value = rows.value.find((row: Row) => row[rowKey] === id);
    const isCurrentlySelected = rowSelection.value[id]?.isSelected;
    rowSelection.value[id] = {
      isSelected: !isCurrentlySelected,
      value,
    };
  }

  function toggleSelectMultipleRows(ids: string[]) {
    const tempRowSelection: RowSelection = {};
    ids.forEach((id) => {
      const value = rows.value.find((row: Row) => row[rowKey] === id);
      const isCurrentlySelected = rowSelection.value[id]?.isSelected;
      tempRowSelection[id] = {
        isSelected: !isCurrentlySelected,
        value,
      };
    });
    rowSelection.value = { ...rowSelection.value, ...tempRowSelection };
  }

  function selectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: true,
        value: row,
      };
    });
  }

  function unselectAll() {
    rows?.value.forEach((row: Row) => {
      const id = row[rowKey];
      rowSelection.value[id] = {
        isSelected: false,
        value: row,
      };
    });
  }

  function resetRowSelection() {
    rowSelection.value = {};
  }

  /** Store */
  const init = async () => {
    const { page, maxPageSize } = pageAndMaxPageSizeQueryParams();
    const columnFilters = columnFiltersQueryParams();
    const searchBy = searchByQueryParam();
    const orderBy = orderByQueryParam();

    initialiseStore(
      orderBy,
      searchBy,
      columnFilters,
      page,
      maxPageSize,
    );
  };

  return {
    init,
    rowSelection,
    selectedRowIds,
    selectedRows,
    toggleSelectRow,
    toggleSelectMultipleRows,
    selectAll,
    unselectAll,
    resetRowSelection,
    isAllRowsSelected,
  };
};
